import React, { useEffect } from 'react'
import Seo from './../../components/seo/seo'
import Layout from './../../components/layout/Layout'
import { Link } from 'gatsby'

const UiuxDesigner = () => {
  useEffect(() => {
    setTimeout(() => {
      window?.$(function () {
        window?.$('input').change(function () {
          if (
            window?.$('#exampleCheck1').is(':checked') &&
            window?.$('#name').val() != '' &&
            window?.$('#email').val() != ''
          ) {
            window?.$('#job-open-form-submit-btn').removeAttr('disabled')
          } else {
            window?.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          }
        })
      })

      window?.$("input[type='file']").on('change', function () {
        if (this.files && this.files[0].size > 1068157) {
          window
            ?.Toastify({
              text: 'Please upload file less than 1MB. Thanks!!',
              duration: 5000,
              newWindow: true,
              gravity: 'bottom', // `top` or `bottom`
              position: 'center', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
              },
            })
            .showToast()
          window?.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          window?.$('#fileUpload').val('')
          const btnTextChange = document.querySelector('#fileTextChange')
          btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
        }
      })

      // call email api
      window?.$(document).ready(function () {
        window?.$('#job-open-form').on('submit', function (e) {
          e.preventDefault()
          window?.$('#job-open-form-submit-btn').text('Please wait..')
          window?.$('#job-open-form-submit-btn').prop('disabled', true)

          var formdata = new FormData(this)

          window?.$.ajax({
            url: 'https://api.immence.com/email/job-application',
            type: 'POST',
            cache: false,
            data: formdata,
            contentType: false,
            processData: false,
            success: function (response) {
              window
                ?.Toastify({
                  text: 'Thank you for contacting us. We will get back to you soon.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #63A4FF, #0BAB64)',
                  },
                })
                .showToast()

              window?.$('#job-open-form-submit-btn').text('Send Message')
              window?.$('#job-open-form-submit-btn').prop('disabled', true)
              window?.$('#job-open-form').trigger('reset')
              window?.$('#fileUpload').val('')
              const btnTextChange = document.querySelector('#fileTextChange')
              btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
            },
            error: function (response) {
              window
                ?.Toastify({
                  text: 'Something went wrong. Please try again later.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
                  },
                })
                .showToast()

              window?.$('#job-open-form-submit-btn').text('Send Message')
              window?.$('#job-open-form-submit-btn').prop('disabled', false)
            },
          })
        })
      })
    }, 1000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window?.AOS?.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      var rellax = new window.Rellax('.rellax', {
        breakpoints: [576, 768, 1201],
      })
      const fileUpload = document.querySelector('#fileUpload')
      const btnTextChange = document.querySelector('#fileTextChange')

      fileUpload.addEventListener('change', (e) => {
        console.log(fileUpload.files)
        btnTextChange.innerHTML = `window?.${fileUpload.files[0].name}`
      })
      // window?.Dropzone.myGreatDropzone = {
      //     paramName: "file", // The name that will be used to transfer the file
      //     maxFilesize: 2, // MB
      //     dictDefaultMessage:
      //         "<strong>Drop files here or click to upload. </strong></br> (This is just a demo dropzone. Selected files are not actually uploaded.)",
      // };
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'Careers | immence'}
        description={'We are always in search of talented people.'}
      />
      <Layout>
        <div>
          <section className="com-section bg-blue hero-section policySection">
            <div className="hero-section-content">
              <div className="container">
                <div className data-aos="fade-up">
                  <h1 className="text-white hero-heading">UI/UX Designer</h1>
                  <div className="d-flex text-white">
                    <div className="job-type me-2">
                      <p>Full time</p>
                    </div>
                    <div className="exp-yrs me-2">
                      <span>•</span>
                      <p>0-1 Years</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section job-opening-overview bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Overview</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="overview-content" data-aos="fade-up">
                    <p>
                      We are looking for a UI/UX Designer to turn our software
                      into easy-to-use products for our clients.
                    </p>
                    <p>
                      UI/UX Designer responsibilities include gathering user
                      requirements, designing graphic elements and building
                      navigation components. To be successful in this role, you
                      should have experience with design software and wireframe
                      tools. If you also have a portfolio of professional design
                      projects that includes work with web/mobile applications,
                      we’d like to meet you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section what-you-do bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Responsibilities</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="job-req" data-aos="fade-up">
                    <ul className="job-req-ul">
                      <li>
                        <p>
                          {' '}
                          Gather and evaluate user requirements in collaboration
                          with product managers and engineers.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Illustrate design ideas using storyboards, process
                          flows and sitemaps.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Design graphic user interface elements, like menus,
                          tabs and widgets.
                        </p>
                      </li>
                      <li>
                        <p> Build page navigation buttons and search fields.</p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Develop UI mockups and prototypes that clearly
                          illustrate how mobile app or website’s function and
                          look like.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Create original graphic designs (e.g., images,
                          sketches and tables).
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Prepare and present rough drafts to internal teams and
                          key stakeholders.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Identify and troubleshoot UX problems (e.g.,
                          responsiveness).
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Conduct layout adjustments based on user feedback.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Adhere to style standards on fonts, colors and images.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section what-you-do bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Skills &amp; Requirements</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="job-req" data-aos="fade-up">
                    <ul className="job-req-ul">
                      <li>
                        <p>
                          {' '}
                          Proven work experience as a UI/UX Designer or similar
                          role.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Up-to-date knowledge of design software like Figma,
                          Adobe XD, Illustrator and Photoshop, etc..
                        </p>
                      </li>
                      <li>
                        <p> Portfolio of design projects.</p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Knowledge of wireframe tools (e.g., Wireframe.cc and
                          InVision).
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Team spirit; strong communication skills to
                          collaborate with various stakeholders.
                        </p>
                      </li>
                      <li>
                        <p> Good time-management skills.</p>
                      </li>
                      <li>
                        <p> Able to adapt new trending software quickly.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section bg-white apply-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="hero-heading" data-aos="fade-up">
                    Apply Here
                  </h2>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="contact-form-box" data-aos="fade-up">
                    <form id="job-open-form">
                      <input
                        type="text"
                        name="job-application-type"
                        id="job-application-type"
                        defaultValue="UI/UX Designer"
                        hidden
                      />
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-6">
                          <label htmlFor="name" className="form-label required">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Type your name"
                            required
                          />
                        </div>
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="email"
                            className="form-label required"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Type your email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="linkedinProfile"
                            className="form-label"
                          >
                            Linkedin Profile
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="linkedinProfile"
                            name="linkedinProfile"
                            placeholder="Type your linkedin profile"
                          />
                        </div>
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="expectedSalary"
                            className="form-label"
                          >
                            Expected Salary
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="expectedSalary"
                            name="expectedSalary"
                            placeholder="Type your expected salary"
                          />
                        </div>
                      </div>
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-12">
                          <label htmlFor="message" className="form-label">
                            Tell us about yourself
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            placeholder="Tell us about yourself"
                            style={{ height: 150 }}
                            defaultValue={''}
                          />
                        </div>
                      </div>
                      <div className="mb-4 form-group col-lg-12">
                        <label htmlFor="file" className="form-label d-block">
                          Upload your CV (or cool stuff we should see)
                        </label>
                        <div className="upload-btn-wrapper">
                          <div className="dropzone dropdown-setting">
                            <div className="upload-btn-wrapper estimate-wrapper mb-4">
                              <div className="d-inline position-relative">
                                <input
                                  className="form-control"
                                  id="fileUpload"
                                  name="file"
                                  type="file"
                                />
                                <button
                                  className="btn btn-outline-light btn-ouline-sm"
                                  id="fileTextChange"
                                >
                                  <i className="fi-rr-cloud-upload me-2 ms-0" />{' '}
                                  Upload a file
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 form-check form-group">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label text-white"
                          htmlFor="exampleCheck1"
                        >
                          I hereby accept{' '}
                          <Link
                            href="/terms-of-use"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white text-underline"
                          >
                            Terms of Use
                          </Link>{' '}
                          and{' '}
                          <Link
                            href="/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white text-underline"
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="job-open-form-submit-btn"
                          className="btn btn-outline-light-fill btn-ouline"
                          disabled="disabled"
                        >
                          Submit Application
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default UiuxDesigner
